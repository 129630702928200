import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {graphql} from "gatsby";

export const data = graphql`query FeesQuery {
  file(relativePath: {eq: "nemo-fees-appendix1.pdf"}) {
    publicURL
  }
}
`

const FeesPage = ({ data }) => (
    <Layout>
        <Seo title={'Nemo’s standard fees'} description={'Nemo personal Finance loans fees for administering our secured loans. Click here now to view a breakdown of all the service fees and their relative costs.'} lang={'en-gb'} />
        <section className="oneCol blue cfx">
            <div className="bdr">
                <div className="bdrTop"></div>
                <div className="bdrMid cfx">
                    <div className="headingArea cfx">
                        <div className="bubble blue cfx cp">
                            <div className="inr">
                                <h1>Our Fees</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <div className="intro">
                            <p className="leading">Our Tariff of Fees and Charges seeks to reflect industry good
                                practice principles as recommended by UK Finance and Which? The objective is to make our
                                fees and charges easy for you to understand. A similar document is being used throughout
                                the industry to help you compare mortgage products. When looking at the fees that other
                                lenders charge, you may notice some that don’t appear in our tariff below. This means we
                                don’t charge these fees.</p>
                        </div>
                    </div>
                    <p>
                    </p>
                    <p>For a&nbsp;tariff of our current fees and charges please click&nbsp;<a
                        href={ data.file.publicURL }>here</a></p>
                    <p>
                        <strong>In addition to the fees listed here, there may be other expenses (as defined in the
                            Terms and Conditions) which you may incur in relation to your Mortgage Agreement. These will
                            form part of your outstanding mortgage debt and must be paid to us in full when they become
                            due. If you would like to know more about the Terms and Conditions of your second charge
                            mortgage, you will find these on the back of your Mortgage Agreement. If you require a copy
                            of your Mortgage Agreement, please <a href="/contact-us">contact us</a>. </strong>
                    </p>
                    <p>
                        <strong>
                            <br />
                        </strong>Fees and information correct as at 12th November 2019 and subject to change to reflect
                        changes to Nemo Personal Finance in the costs of these services. Please&nbsp;<a
                        href="/contact-us">contact
                        us</a>&nbsp;if you have any questions.<br /></p>
                </div>
                <div className="bdrBot"></div>
            </div>
        </section>
    </Layout>
)

export default FeesPage;